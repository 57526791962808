import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import FiraNavBar from "../../../components/FiraNavBar/FiraNavBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./ClientDetailView.module.scss";
import CardWidget from "../../../widgets/CardWidget/CardWidget";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import Button, { ButtonStyle } from "../../../components/Button/Button";
import {
  InfiniteTable,
  TableButton,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  orderingType,
} from "../../../components/Table/Table";
import { useDebounce } from "../../../utils/useDebounce";
import EmptyPieChartWidget from "../../../components/FiraIcons/EmptyPieChartWidget";

import {
  FiraAccountState,
  OptionsSelect,
  ServicesName,
  UserRoles,
} from "../../../utils/types.d";
import Skeleton from "../../../components/Skeleton/Skeleton";
import EventListWidget from "../../../components/EventListWidget/EventListWidget";
import WorldSvg from "../../../components/FiraIcons/world";
import PhoneSvg from "../../../components/FiraIcons/PhoneSvg";
import PlayerSvg from "../../../components/FiraIcons/PlayerSvg";
import OperStoreModal from "../../../components/Modals/OperStoreModal/OperStoreModal";
import noImageStoreSVG from "../../../assets/svg/no-image-store.svg";
import {
  ClientContextData,
  ClientsFilter,
  StoreData,
} from "../../../utils/typesClients.d";
import { ClientsContext } from "../../../providers/clientsProvider";
import { dateFormat } from "../../../utils/handleDates";
import EditServiceModal from "../../../components/Modals/EditServiceModal/EditServiceModal";
import {
  usePopupAlert,
  AlertType,
} from "../../../providers/AlertProvider/AlertProvider";
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import AdditionalDataModal from "./AdditionDataModal";
const DetailAccountOwnerView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState("");
  const debounced = useDebounce(filterValue, 800);
  const { utils, auth } = useSelector((state: RootState) => state);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [showOperModal, setShowOperModal] = useState(false);
  const [showEditServiceModal, setShowEditServicesModal] = useState(false);
  const { id } = useParams();
  const {
    accountOwner,
    generalData,
    lastEvent,
    storeList,
    loadingData,
    getAccountOwnerInfo,
    getGeneralAccountInfo,
    getLastEventInfo,
    getStoresListInfo,
    setDetailsData,
    isError,
    updateFiraProducts,
    setEditMode,
    isEdit,
    toggleSignUp,
    togglePasswordField,
    setSignUpExpressRole,
    isErrorProducts,
    toggleCustomField,
    saveCustomFields,
  } = useContext(ClientsContext) as ClientContextData;

  const [showCustomDataFields, setShowCustomDataFields] = React.useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [currentStore, setCurrentStore] = useState<StoreData>();
  const [storeListItem, setStoreListItem] = useState<StoreData[]>([]);
  const showAlert = usePopupAlert();
  const signUpExpressRoles: OptionsSelect[] = [
    { id: 0, value: UserRoles.ADMIN, name: UserRoles.ADMIN },
    { id: 1, value: UserRoles.OPERATOR, name: UserRoles.OPERATOR },
    { id: 2, value: UserRoles.MODERATOR, name: UserRoles.MODERATOR },
  ];
  /**
   * Events handle
   */
  const handleBackButton = () => {
    navigate(-1);
    setDetailsData();
  };

  const handleDebouncedFilter = () => {
    if (filterValue.length != 0) {
      getStoresListInfo(
        id ? id : "",
        filterValue,
        ClientsFilter.descend,
        0,
        15
      );
    } else {
      getStoresListInfo(id ? id : "", "", ClientsFilter.descend, 0, 10);
    }
  };

  const handleEventFiltering = (event: ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    setFilterValue(keyword);
  };

  const handleOrdering = (id: number) => {
    const selected = orderingList.filter((p) => {
      return p.id === id;
    });
    setSelectedFilter(selected[0]);
  };

  const onChangeOption = (option: OptionsSelect) => {
    setSignUpExpressRole(id || "", option.value);
  };

  const onCloseModal = () => {
    setShowOperModal(false);
  };
  /**
   * UseEffects definitions
   */
  useEffect(() => {
    if (utils.screenWidth < 900) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [utils.screenWidth]);

  useEffect(() => {
    !firstLoad && handleDebouncedFilter();
  }, [debounced]);

  useEffect(() => {
    //

    getGeneralAccountInfo(id ? id : "");
    getAccountOwnerInfo(id ? id : "");
    getLastEventInfo(id ? id : "");
    getStoresListInfo(id ? id : "", "", ClientsFilter.descend, 0, 15);
  }, []);

  /**
   * init Object and components
   */

  const storesHeader = [
    { name: "", size: "5px" },
    { name: t("views.clients.details.table.storeName"), size: "100px" },
    { name: t("views.clients.details.table.state"), size: "50px" },
    { name: t("views.clients.details.table.operators"), size: "15%" },
    { name: t("views.clients.details.table.idStore"), size: "50px" },
    { name: "", size: "50px" },
    { name: "", size: "15px" },
  ];

  const orderingList = [
    {
      id: 3,
      name: t("views.clients.filterOptions.alphabet"),
      value: ClientsFilter.ascend,
    },
    {
      id: 4,
      name: t("views.clients.filterOptions.reverse"),
      value: ClientsFilter.descend,
    },
  ];

  const [selectedFilter, setSelectedFilter] = useState<orderingType>(
    orderingList[0]
  );

  useEffect(() => {
    setFirstLoad(false);
    getStoresListInfo(id ? id : "", "", selectedFilter.value + "", 0, 15);
  }, [selectedFilter]);

  const [loadingRedirect, setLoadingRedirect] = useState(false);

  const handleButtonClick = async () => {
    setLoadingRedirect(true);

    if (auth.user) {
      window.open(
        process.env.REACT_APP_CMS +
          "?t=" +
          auth.user.token +
          "&u=" +
          auth.user.id +
          "&b=" +
          id,
        "_blank"
      );
    }
    setLoadingRedirect(false);
  };

  const enterButton = (
    <Button
      width="173px"
      clickHandler={handleButtonClick}
      isDisabled={loadingRedirect}
    >
      <label className={styles.textButton}>
        {t("views.clients.enterButton")}
      </label>
    </Button>
  );
  const containerCards = (
    <div
      className={`${styles.countersWrapper} ${
        isMobile ? styles.mobileCounter : ""
      }`}
    >
      <CardWidget
        title={t("views.clients.details.cards.livesCount")}
        description={t("views.dashboard.cards.totalAudience")}
        data={generalData?.lives + ""}
        isLoading={loadingData["loadcards"]}
      />

      <CardWidget
        title={t("views.clients.details.cards.minutes")}
        description={t("views.dashboard.cards.numberOfLives")}
        data={generalData?.minutes + ""}
        isLoading={loadingData["loadcards"]}
      />
      <CardWidget
        title={t("views.clients.details.cards.stores")}
        description={t("views.dashboard.cards.testLives")}
        data={generalData?.stores + ""}
        isLoading={loadingData["loadcards"]}
      />
      <CardWidget
        title={t("views.clients.details.cards.users")}
        description={t("views.dashboard.cards.averageViews")}
        data={generalData?.users + ""}
        isLoading={loadingData["loadcards"]}
      />
    </div>
  );

  const header = (
    <TableRow>
      {storesHeader.map((head, i) => (
        <TableHead key={i} size={head.size} centerContent={i !== 1}>
          <label className={styles.titleHeaderTable}> {head.name} </label>
        </TableHead>
      ))}
    </TableRow>
  );
  const handleCloseDetailsOper = () => {
    //navigate(`${store.storeId}`);
    setShowOperModal(false);
  };

  const loadMoreData = () => {
    getStoresListInfo(
      id ? id : "",
      "",
      selectedFilter.value + "",
      storeListItem.length + 1,
      5
    );
  };

  useEffect(() => {
    setStoreListItem(storeList);
  }, [storeList]);

  const handleEditService = () => {
    setShowEditServicesModal(true);
  };
  const handleCloseEditService = () => {
    setShowEditServicesModal(false);
  };

  const handleSaveEditService = (productsOn: string[]) => {
    updateFiraProducts(id ? id : "", productsOn);

    setShowEditServicesModal(false);
  };
  useEffect(() => {
    if (isEdit && !isErrorProducts) {
      showAlert(
        AlertType.success,
        t("views.clients.details.editService.messageSuccessEdit")
      );
      setEditMode(false);
    }
  }, [accountOwner?.productsOn]);

  const body = storeListItem.map((store, i) => {
    const handleClickDetails = () => {
      //navigate(`${store.storeId}`);
      setCurrentStore(store);
      setShowOperModal(true);
    };

    return (
      <TableRow key={i}>
        <TableCell centerContent>
          <div className={styles.tableImg}>
            <img
              src={
                store.logo !== null && store.logo.length != 0
                  ? store.logo
                  : noImageStoreSVG
              }
            />
          </div>
        </TableCell>
        <TableCell>
          <label className={styles.labelItem}>{store.storeName}</label>
        </TableCell>
        <TableCell centerContent>
          <label
            className={`${styles.itemStatus} ${
              store.state == FiraAccountState.active
                ? styles.active
                : styles.inactive
            }`}
          >
            {store.state === FiraAccountState.active
              ? t("views.clients.details.active")
              : t("views.clients.details.inactive")}
          </label>
        </TableCell>
        <TableCell centerContent>
          <label className={styles.labelItem}>{store.operators}</label>
        </TableCell>
        <TableCell centerContent>
          <div className={styles.centertd}>
            <div className={styles.textid}>
              {" "}
              <label className={styles.labelItem}>{store.storeId}</label>
            </div>
          </div>
        </TableCell>
        <TableCell RightContent>
          <div className={styles.buttonTd}>
            <Button
              clickHandler={handleClickDetails}
              buttonStyle={ButtonStyle.transparent}
              width="80%"
              height="48px"
            >
              <label className={styles.labelDetail}>
                {t("views.clients.details.details")}
              </label>
            </Button>
          </div>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    );
  });

  return (
    <div className={styles.accountOwnerDetailContainer}>
      <FiraNavBar
        backButtonHandler={handleBackButton}
        titleSection={
          <label className={styles.titleSection}>{accountOwner?.name}</label>
        }
        trailingOptions={[enterButton]}
      ></FiraNavBar>
      {showOperModal && (
        <OperStoreModal onClose={handleCloseDetailsOper} store={currentStore} />
      )}
      {showEditServiceModal && (
        <EditServiceModal
          onClose={handleCloseEditService}
          onSave={handleSaveEditService}
          listServices={accountOwner ? accountOwner.productsOn : []}
        />
      )}
      <div className={styles.countersWrapper}>{containerCards}</div>
      {!isMobile ? (
        <div className={styles.desktopContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.tableContainer}>
              <div style={{ borderBottom: "1px solid var(--borde, #D5DDE0)" }}>
                <TableHeader
                  hideCheckBox
                  searchInputValue={filterValue}
                  searchInputChangeHandle={handleEventFiltering}
                  orderingList={orderingList}
                  orderingValue={selectedFilter}
                  orderingClick={handleOrdering}
                />
              </div>
              <div className={styles.infiniteTableWrapper}>
                {loadingData["loadstores"] && (
                  <InfiniteTable
                    header={header}
                    body={SkeletonTableBody}
                    rowsNumber={storeListItem.length}
                    loadData={() => ({})}
                    rowLoader={<RowLoader />}
                    hasMore={storeListItem.length >= 6}
                  />
                )}
                {storeListItem.length > 0 ? (
                  <InfiniteTable
                    header={header}
                    body={storeListItem.length === 0 ? SkeletonTableBody : body}
                    rowsNumber={storeListItem.length}
                    loadData={() => loadMoreData()}
                    rowLoader={<RowLoader />}
                    hasMore={storeListItem.length >= 15 && !isError}
                  />
                ) : (
                  <div className={styles.emptyInfiniteTableWrapper}>
                    <EmptyClientsView />
                  </div>
                )}
              </div>
            </div>

            <EventListWidget
              isLoading={loadingData["loadevents"]}
              nextEventList={lastEvent}
              handleEventList={() => ({})}
            />
          </div>

          <div className={styles.VerticalContainer}>
            {accountOwner && (
              <div className={styles.SwitchContainer}>
                <div className={styles.TitleWrapper}>
                  <span>{t("toggle.SignUp")}</span>
                </div>
                <div className={styles.ContentWrapper}>
                  <ToggleSwitch
                    checked={accountOwner?.signOnExpress === "ENABLED"}
                    handleChecked={() => toggleSignUp(id || "")}
                    hideTags
                  />
                </div>
                <div
                  className={`${styles.SubTitleWrapper} ${
                    accountOwner?.signOnExpress === "DISABLED"
                      ? styles.Disabled
                      : ""
                  }`}
                >
                  <span>{t("toggle.PasswordField")}</span>
                </div>
                <div className={styles.ContentWrapper}>
                  <ToggleSwitch
                    checked={accountOwner?.fieldEnabled === true}
                    handleChecked={() => togglePasswordField(id || "")}
                    hideTags
                    disable={accountOwner?.signOnExpress === "DISABLED"}
                  />
                </div>
                <div className={styles.SubTitleWrapper}>
                  <span>{t("form.UserRole")}</span>
                </div>
                <div className={styles.ContentWrapper}>
                  <CustomSelect
                    width="100%"
                    options={signUpExpressRoles}
                    value={
                      accountOwner.role ? accountOwner.role : UserRoles.OPERATOR
                    }
                    handleSelected={onChangeOption}
                  />
                </div>
                <div className={styles.SubTitleWrapper}>
                  <span>{t("form.AdditionalData")}</span>
                </div>
                <div
                  className={`${styles.ContentWrapper} ${styles.FlexWrapper}`}
                >
                  <ToggleSwitch
                    checked={
                      accountOwner.allowCustomFields !== null &&
                      accountOwner.allowCustomFields
                    }
                    handleChecked={() => toggleCustomField(id || "")}
                    hideTags
                    disable={accountOwner?.signOnExpress === "DISABLED"}
                  />
                  <Button
                    isDisabled={
                      (accountOwner.allowCustomFields !== null &&
                        !accountOwner.allowCustomFields) ||
                      accountOwner?.signOnExpress === "DISABLED"
                    }
                    width="auto"
                    clickHandler={() =>
                      setShowCustomDataFields((prevState) => !prevState)
                    }
                  >
                    <label>{t("button.editform")}</label>
                  </Button>
                </div>
              </div>
            )}

            <div className={styles.rightContainer}>
              {loadingData["loadinfo"] ? (
                <div className={styles.emptyInfiniteTableWrapper}>
                  <RowLoaderContainer />
                </div>
              ) : (
                <>
                  <div className={styles.topContainer}>
                    <div className={styles.cardContainer}>
                      <label className={styles.title} htmlFor="title">
                        {"" + accountOwner?.username}
                      </label>
                      <label
                        className={styles.infoTextTitle}
                        htmlFor="nameAccount"
                      >
                        {"" + accountOwner?.name}
                      </label>
                      <label className={styles.infoText} htmlFor="created">
                        {accountOwner?.createdAt &&
                          t("views.clients.details.createdOn") +
                            dateFormat(new Date(accountOwner?.createdAt + ""))}
                      </label>
                    </div>
                    <div className={styles.cardContainer}>
                      <label className={styles.title} htmlFor="title">
                        {t("views.clients.details.contact")}
                      </label>
                      <label className={styles.infoText} htmlFor="name">
                        {"" + accountOwner?.name}
                      </label>
                      <label className={styles.infoText} htmlFor="created">
                        {accountOwner?.accountEmail
                          ? "" + accountOwner?.accountEmail
                          : ""}
                      </label>
                      <label className={styles.infoText} htmlFor="created">
                        {accountOwner?.contact &&
                          accountOwner?.contact.phones &&
                          accountOwner?.contact.phones.length > 0 &&
                          "+" +
                            accountOwner?.contact.phones[0].country +
                            " " +
                            accountOwner?.contact.phones[0].number}
                      </label>
                    </div>

                    <div className={styles.cardContainer}>
                      <label className={styles.title} htmlFor="country">
                        {t("views.clients.details.country")}
                      </label>
                      <label className={styles.infoText} htmlFor="name">
                        {"-"}
                      </label>
                    </div>

                    <div className={styles.cardContainer}>
                      <label className={styles.title} htmlFor="title">
                        {t("views.clients.details.state")}
                      </label>
                      <Button
                        width="80px"
                        height="24px"
                        buttonStyle={ButtonStyle.border}
                        clickHandler={() => {
                          //openImportProductsModal();
                        }}
                      >
                        <span className="body-12-low-text">
                          {accountOwner?.accountState === "ACTIVE"
                            ? t("views.clients.details.active")
                            : t("views.clients.details.inactive")}
                        </span>
                      </Button>
                    </div>

                    <div className={styles.cardContainer}>
                      <div className={styles.rowContainer}>
                        <label className={styles.title} htmlFor="title">
                          {t("views.clients.details.services")}
                        </label>
                        <Link
                          to={""}
                          className={styles.editlink}
                          onClick={handleEditService}
                        >
                          {t("views.clients.details.edit")}
                        </Link>
                      </div>
                      <div className={styles.rowContainerGrid}>
                        {accountOwner &&
                          accountOwner.productsOn.map((item, key) => (
                            <div key={key} className={styles.item}>
                              {item === ServicesName.PROSELLER ? (
                                <PhoneSvg key={key} />
                              ) : item === ServicesName.FIRA_TV ? (
                                <PlayerSvg key={key} />
                              ) : item === ServicesName.FIRA_WEB ? (
                                <WorldSvg key={key} />
                              ) : (
                                <></>
                              )}

                              <label htmlFor="title">
                                {item === ServicesName.PROSELLER
                                  ? t("views.clients.details.proseller")
                                  : item === ServicesName.FIRA_TV
                                  ? t("views.clients.details.firatv")
                                  : t("views.clients.details.firaweb")}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className={styles.bottomContainer}>
                    <div
                      className={styles.cardContainer}
                      style={{ marginTop: "150px" }}
                    >
                      <Button
                        buttonStyle={ButtonStyle.borderYellow}
                        width="100%"
                      >
                        <label className={styles.textButton}>
                          {t("views.clients.details.transfer")}
                        </label>
                      </Button>
                      <Button buttonStyle={ButtonStyle.borderRed} width="100%">
                        <label
                          className={`${styles.textButton} ${styles.textButtonRed}`}
                        >
                          {t("views.clients.details.desactivate")}
                        </label>
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.mobileContainer}>
          <div className={styles.subGrid}>
            <div className={styles.subGrid__views}>
              <div className={styles.leftContainer}>
                {showOperModal && (
                  <OperStoreModal
                    onClose={handleCloseDetailsOper}
                    store={currentStore}
                  />
                )}

                <div className={styles.tableContainer}>
                  <div
                    style={{
                      borderBottom: "1px solid var(--borde, #D5DDE0)",
                    }}
                  >
                    <TableHeader
                      hideCheckBox
                      searchInputValue={filterValue}
                      searchInputChangeHandle={handleEventFiltering}
                      orderingList={orderingList}
                      orderingValue={selectedFilter}
                      orderingClick={handleOrdering}
                    />
                    {loadingData["loadstores"] && (
                      <div className={styles.infiniteTableWrapper}>
                        <InfiniteTable
                          header={header}
                          body={SkeletonTableBody}
                          rowsNumber={storeList.length}
                          loadData={() => ({})}
                          rowLoader={<RowLoader />}
                          hasMore={storeList.length >= 20}
                        />
                      </div>
                    )}
                  </div>

                  {storeList.length > 0 ? (
                    <div className={styles.infiniteTableWrapper}>
                      <InfiniteTable
                        header={header}
                        body={storeList.length === 0 ? SkeletonTableBody : body}
                        rowsNumber={storeList.length}
                        loadData={() => ({})}
                        rowLoader={<RowLoader />}
                        hasMore={storeList.length >= 20}
                      />
                    </div>
                  ) : (
                    <div className={styles.emptyInfiniteTableWrapper}>
                      <EmptyClientsView />
                    </div>
                  )}
                </div>

                <EventListWidget
                  isLoading={loadingData["loadevents"]}
                  nextEventList={lastEvent}
                  handleEventList={() => ({})}
                />
              </div>
              <div className={styles.rightContainerMobile}>
                {loadingData["loadinfo"] ? (
                  <div className={styles.emptyInfiniteTableWrapper}>
                    <RowLoaderContainerMobile />
                  </div>
                ) : (
                  <>
                    <div className={""}>
                      <div className={styles.cardContainer}>
                        <label className={styles.title} htmlFor="title">
                          {"" + accountOwner?.username}
                        </label>
                        <label
                          className={styles.infoTextTitle}
                          htmlFor="nameAccount"
                        >
                          {"" + accountOwner?.name}
                        </label>
                        <label className={styles.infoText} htmlFor="created">
                          {accountOwner?.createdAt &&
                            t("views.clients.details.createdOn") +
                              dateFormat(
                                new Date(accountOwner?.createdAt + "")
                              )}
                        </label>
                      </div>
                      <div className={styles.itemRow}>
                        <div className={styles.cardContainer}>
                          <label className={styles.title} htmlFor="title">
                            {t("views.clients.details.country")}
                          </label>
                          <label className={styles.infoText} htmlFor="name">
                            {"- "}
                          </label>
                        </div>
                        <div className={styles.cardContainer}>
                          <label className={styles.title} htmlFor="title">
                            {t("views.clients.details.state")}
                          </label>
                          <Button
                            width="80px"
                            buttonStyle={ButtonStyle.border}
                            clickHandler={() => {
                              //openImportProductsModal();
                            }}
                          >
                            <span className="body-12-low-text">
                              {accountOwner?.accountState === "ACTIVE"
                                ? t("views.clients.details.active")
                                : t("views.clients.details.inactive")}
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className={styles.cardContainer}>
                      <label className={styles.title} htmlFor="title">
                        {t("views.clients.details.contact")}
                      </label>
                      <label className={styles.infoText} htmlFor="name">
                        {"" + accountOwner?.name}
                      </label>
                      <label className={styles.infoText} htmlFor="created">
                        {accountOwner?.accountEmail
                          ? "" + accountOwner?.accountEmail
                          : ""}
                      </label>
                      <label className={styles.infoText} htmlFor="created">
                        {accountOwner?.contact &&
                          accountOwner?.contact.phones &&
                          "+" +
                            accountOwner?.contact.phones[0].country +
                            " " +
                            accountOwner?.contact.phones[0].number}
                      </label>
                    </div>

                    <div className={styles.cardContainer}>
                      <div className={styles.rowContainer}>
                        <label className={styles.title} htmlFor="title">
                          {t("views.clients.details.services")}
                        </label>
                        <Link
                          to={""}
                          className={styles.editlink}
                          onClick={handleEditService}
                        >
                          {t("views.clients.details.edit")}
                        </Link>
                      </div>
                      <div className={styles.rowContainerGrid}>
                        {accountOwner &&
                          accountOwner.productsOn.map((item, key) => (
                            <div key={key} className={styles.item}>
                              {item === ServicesName.PROSELLER ? (
                                <PhoneSvg key={key} />
                              ) : item === ServicesName.FIRA_TV ? (
                                <PlayerSvg key={key} />
                              ) : (
                                <WorldSvg key={key} />
                              )}

                              <label htmlFor="title">
                                {item === ServicesName.PROSELLER
                                  ? t("views.clients.details.proseller")
                                  : item === ServicesName.FIRA_TV
                                  ? t("views.clients.details.firatv")
                                  : t("views.clients.details.firaweb")}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className={styles.cardContainerBottom}>
                      <div className={styles.itemRow}>
                        <Button
                          buttonStyle={ButtonStyle.borderRed}
                          width="100%"
                        >
                          <label>
                            {t("views.clients.details.desactivate")}
                          </label>
                        </Button>
                        <Button
                          buttonStyle={ButtonStyle.borderYellow}
                          width="100%"
                        >
                          <label>{t("views.clients.details.transfer")}</label>
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showCustomDataFields && accountOwner && (
        <AdditionalDataModal
          customData={accountOwner.customFields}
          onSave={(customData) => saveCustomFields(id || "", customData)}
          onClose={() => setShowCustomDataFields(false)}
        />
      )}
    </div>
  );
};

const EmptyInfoView = () => {
  return (
    <div className={styles.emptyWrapper}>
      <EmptyPieChartWidget width={247} />
    </div>
  );
};

const placeholder = [
  { id: 1, name: "placeholder" },
  { id: 2, name: "placeholder" },
  { id: 3, name: "placeholder" },
  { id: 4, name: "placeholder" },
  { id: 5, name: "placeholder" },
  { id: 6, name: "placeholder" },
  { id: 7, name: "placeholder" },
];
const SkeletonTableBody = placeholder.map((data) => {
  return (
    <TableRow key={data.id}>
      <TableCell size="8px"></TableCell>
      <TableCell>
        <Skeleton width="50px" height="50px" />
      </TableCell>
      <TableCell size="300px">
        <div className={styles.nameCell}>
          <Skeleton width="300px" />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={`${styles.topCells} ${styles.selectVisibility} `}>
          <Skeleton />
        </div>
      </TableCell>
      <TableCell centerContent>
        <div className={styles.topCells}>
          <Skeleton width="200px" />
        </div>
      </TableCell>
      <TableCell size="50px">
        <TableButton />
      </TableCell>
    </TableRow>
  );
});

const EmptyClientsView = () => {
  return (
    <div className={styles.emptyWrapper}>
      <EmptyPieChartWidget />
    </div>
  );
};

const RowLoader = () => {
  return (
    <table className={styles.fakeTable}>
      <tr className={styles.fakeRow} key={"loader"}>
        <td
          className={`${styles.first} ${styles.fakeCell}`}
          style={{ width: "8px" }}
        ></td>
        <td
          className={styles.fakeCell}
          style={{ width: "57px", minWidth: "50px" }}
        >
          <Skeleton width="50px" height="50px" />
        </td>
        <td
          className={styles.fakeCell}
          style={{ width: "300px", minWidth: "300px" }}
        >
          {/* por alguna razon esta celda agrega un margen interno, asi que lo cancelamos con marginRight */}
          <div style={{ marginRight: "-41px", width: "fit-content" }}>
            <Skeleton width="300px" />
          </div>
        </td>
        <td className={styles.fakeCell} style={{ minWidth: "100px" }}>
          <div className={styles.centered}>
            <Skeleton />
          </div>
        </td>
        <td className={styles.fakeCell} style={{ minWidth: "200px" }}>
          <div className={styles.centered}>
            <Skeleton width="200px" />
          </div>
        </td>
        <td
          className={`${styles.last} ${styles.fakeCell}`}
          style={{ width: "50px" }}
        ></td>
      </tr>
    </table>
  );
};

const RowLoaderContainer = () => {
  return (
    <table className={styles.fakeTable}>
      <TableRow key={"1"}>
        <TableCell>
          <Skeleton width="90%" height="50px" />
        </TableCell>
      </TableRow>
      <TableRow key={"2"}>
        <TableCell>
          <Skeleton width="0px" height="50px" />
        </TableCell>
      </TableRow>
      <TableRow key={"3"}>
        <TableCell>
          <Skeleton width="90%" height="50px" />
        </TableCell>
      </TableRow>
      <TableRow key={"4"}>
        <TableCell>
          <Skeleton width="0px" height="50px" />
        </TableCell>
      </TableRow>
      <TableRow key={"5"}>
        <TableCell>
          <Skeleton width="90%" height="50px" />
        </TableCell>
      </TableRow>
      <TableRow key={"6"}>
        <TableCell>
          <Skeleton width="0px" height="200px" />
        </TableCell>
      </TableRow>
      <TableRow key={"7"}>
        <TableCell>
          <Skeleton width="90%" height="50px" />
        </TableCell>
      </TableRow>
    </table>
  );
};

const RowLoaderContainerMobile = () => {
  return (
    <table className={styles.fakeTable}>
      <TableRow key={"1"}>
        <TableCell>
          <Skeleton width="400px" height="50px" />
        </TableCell>
      </TableRow>
      <TableRow key={"2"}>
        <TableCell>
          <Skeleton width="400px" height="50px" />
        </TableCell>
      </TableRow>
    </table>
  );
};

export default DetailAccountOwnerView;
