import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  loginMicrosoftService,
  loginService,
  refreshTokenService,
} from "../../services/authentication";

export const loginMicrosoft = createAsyncThunk(
  "auth/login",
  async (token: string) => {
    const response = await loginMicrosoftService(token);
    return {authData: response.data, token};
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (token: string) => {
    const response = await loginService(token);
    return {authData: response.data, token};
  }
);

export const refreshTokenAction = createAsyncThunk(
  "auth/refreshToken",
  async (sessionId: string) => {
    const response = await refreshTokenService(sessionId);
    return response.data;
  }
);
