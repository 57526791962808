import { PublicClientApplication } from "@azure/msal-browser";

export const GOOGLE_CLIENT_ID = `${process.env.REACT_APP_OAUTH_CLIENT_ID}`;


const msalConfig = {
  auth: {
    clientId: "" + process.env.REACT_APP_OAUTH_CLIENT_ID,

    authority:
      process.env.REACT_APP_OAUTH_AUTHORITY +
      "" +
      process.env.REACT_APP_OAUTH_TENANT_ID,
    redirectUri: "" + process.env.REACT_APP_OAUTH_REDIRECT_URL,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
