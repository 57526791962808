import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../../utils/typesStates";
import { loginMicrosoft } from "../../store/auth/authActions";
import { useAppDispatch } from "../../store/Store";
import { useMsal } from "@azure/msal-react";

const client_id = process.env.REACT_APP_OAUTH_CLIENT_ID;

const AuthGuard = () => {
  const { isLogging } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const { instance, accounts } = useMsal();
  const USER_ACCOUNT = accounts[0];


  const checkSession = async () => {
    if (USER_ACCOUNT) {
      try {
        const response = await instance.acquireTokenSilent({
          scopes: ["api://" + client_id + "/.default"],
        });

        dispatch(loginMicrosoft(response.accessToken));
      } catch (error) {
        // El token no es válido o ha expirado
        console.error("Error obtaining access token:", error);
      }
    }
  };

  useEffect(() => {
    if (!USER_ACCOUNT) {
      checkSession();
    }
  }, [USER_ACCOUNT]);

  return isLogging ? <Outlet /> : <Navigate to="/" replace />;
};

export default AuthGuard;
