import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/Store";
import { useTranslation } from "react-i18next";

import FiraLiveLogo from "../FiraIcons/FiraLiveLogo";
import styles from "./Login.module.scss";
import Button, { ButtonStyle } from "../Button/Button";
import MicrosoftLogo from "../FiraIcons/MicrosoftLogo";
import { login, loginMicrosoft } from "../../store/auth/authActions";
import { RootState } from "../../utils/typesStates";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { Icon } from "@iconify-icon/react";
import { useMsal } from "@azure/msal-react";

const client_id = process.env.REACT_APP_OAUTH_CLIENT_ID;

interface LoginProps {
  onSuccessLogin(): void;
  showError?: boolean;
}

const Login: React.FC<LoginProps> = ({ onSuccessLogin, showError }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { auth } = useSelector((state: RootState) => state);
  const { instance, accounts } = useMsal();
  const USER_ACCOUNT = accounts[0];
  const [isDisabled, setIsDisabled] = React.useState(false);

  // const onLoginSuccess = (tokenResponse: TokenResponse) => {
  //   dispatch(login(tokenResponse.access_token));
  // };

  // const onLoginError = () => {
  //   console.error("GOOGLE LOGIN ERROR");
  // };

  const loginToMicrosoft = async () => {
    try {
      const response = await instance.loginPopup();
      dispatch(loginMicrosoft(response.accessToken));
    } catch (error) {
      console.error("ERROR ON LOGIN", error);
      setIsDisabled(false);
    }
  };

  const checkSession = async () => {
    if (USER_ACCOUNT) {
      try {
        setIsDisabled(true);
        const response = await instance.acquireTokenSilent({
          scopes: ["api://" + client_id + "/.default"],
        });
        dispatch(loginMicrosoft(response.accessToken));
      } catch (error) {
        console.error("ERROR ON RETRIVING TOKEN", error);
        loginToMicrosoft();
      }
    } else {
      setIsDisabled(false);
      loginToMicrosoft();
    }
  };

  // const googleLogin = useGoogleLogin({
  //   onSuccess: onLoginSuccess,
  //   onError: onLoginError,
  //   scope: "https://www.googleapis.com/auth/admin.directory.user.readonly",
  // });

  useEffect(() => {
    if (auth.isLogging) {
      onSuccessLogin();
    }
  }, [auth.isLogging]);

  return (
    <div className={styles.Container}>
      <div className={styles.Logo}>
        <FiraLiveLogo />
      </div>

      <label className={styles.Title}>{t("auth.title")}</label>
      <label className={styles.SubTitle}>{t("auth.subtitle")}</label>
      <div style={{ width: "100%" }}>
        <Button
          buttonStyle={ButtonStyle.border}
          width="100%"
          height="40px"
          clickHandler={checkSession}
          isDisabled={isDisabled}
        >
          <Icon icon="f7:logo-microsoft" width="90" height="90" />
        </Button>
        {showError && (
          <label className={styles.ErrorText}>
            {t("auth.msgErrorValidToken")}
          </label>
        )}
      </div>
    </div>
  );
};

export default Login;
