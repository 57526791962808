import React from "react";
import ModalLayout from "../../../layout/ModalLayout/ModalLayout";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify-icon/react";
import { Button } from "../../../components";
import { ButtonStyle } from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";

interface Props {
  customData: { [key: string]: string } | null;
  onSave(customData: { [key: string]: string }): void;
  onClose(): void;
}
const AdditionalDataModal: React.FC<Props> = ({ onClose, customData, onSave }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = React.useState<{ [key: string]: string }>(
    customData || {}
  );
  const onSubmit = () => {
    onSave(formData);
    onClose();
  };

  const handleAddKey = () => {
    if (Object.keys(formData).length >= 5) return;
    if (!Object.prototype.hasOwnProperty.call(formData, "")) {
      setFormData((prev) => ({
        ...prev,
        "": "",
      }));
    }
  };

  const handleRemoveKey = (key: string) => {
    setFormData((prevState) => {
      const updatedData = { ...prevState };
      delete updatedData[key];
      return updatedData;
    });
  };

  const handleKeyChange = (oldKey: string, newKey: string) => {
    if (oldKey === newKey) return;
    if (Object.prototype.hasOwnProperty.call(formData, newKey)) return;

    setFormData((prev) => {
      const updatedData = { ...prev, [newKey]: prev[oldKey] };
      delete updatedData[oldKey];
      return updatedData;
    });
  };

  const renderCustomDataInputs = (key: string, value: string) => {
    return (
      <div style={InputWrapper}>
        <Input
          value={key}
          onChange={(e) => handleKeyChange(key, e.target.value)}
          width="100%"
          height="40px"
        />
        <Button
          clickHandler={() => handleRemoveKey(key)}
          buttonStyle={ButtonStyle.borderRed}
          type={"button"}
          width="40px"
          height="40px"
        >
          <Icon icon="solar:trash-bin-2-bold" style={{ color: "#f16063" }} />
        </Button>
      </div>
    );
  };

  return (
    <ModalLayout>
      <form onSubmit={onSubmit} style={Modal}>
        <div style={Header}>
          <label>{t("modal.AdditionalData.header")}</label>
          <Button
            buttonStyle={ButtonStyle.transparent}
            clickHandler={onClose}
            width="16px"
            height="16px"
            type="button"
          >
            <Icon icon="maki:cross" height={16} width={16} />
          </Button>
        </div>
        <div style={Body}>
          {formData &&
            Object.keys(formData).map((key) =>
              renderCustomDataInputs(key, formData[key])
            )}
          <Button
            clickHandler={handleAddKey}
            type={"button"}
            width="auto"
            height="40px"
            isDisabled={
              !formData || (formData && Object.keys(formData).length >= 5)
            }
          >
            <>{t("modal.AdditionalData.addParam")}</>
          </Button>
        </div>
        <div style={Footer}>
          <Button buttonStyle={ButtonStyle.border} type="button">
            <>{t("button.cancel")}</>
          </Button>
          <Button buttonStyle={ButtonStyle.main} type="submit">
            <>{t("button.save")}</>
          </Button>
        </div>
      </form>
    </ModalLayout>
  );
};

const Modal: React.CSSProperties = {
  width: 800,
  minHeight: 200,
  backgroundColor: "#fff",
  borderRadius: 8,
  display: "grid",
  gridTemplateRows: "auto 1fr auto",
};

const Header: React.CSSProperties = {
  padding: 16,
  borderBottom: "1px solid #d1d8db",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const Footer: React.CSSProperties = {
  borderTop: "1px solid #d1d8db",
  padding: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,
};

const Body: React.CSSProperties = {
  padding: "16px 32px",
};

const InputWrapper: React.CSSProperties = {
  display: "grid",
  gridTemplateColumns: "1fr auto",
  gap: 16,
  paddingBottom: "16px",
};

export default AdditionalDataModal;
