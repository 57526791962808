import { NextEventList, UserRoles } from "./types";

export enum ClientsFilter {
  descend = "DESCEND_ALPHABET",
  ascend = "ASCEND_ALPHABET",
  newer = "ACTIVATION_DATE_DESCEND",
  older = "ACTIVATION_DATE_ASCEND",
  broadcastingActive = "BROADCASTING_ACTIVE",
  broadcastingNotActive = "BROADCASTING_NOT_ACTIVE",
  newerCreation = "NEWER_CREATION_DATE",
  olderCreation = "OLDER_CREATION_DATE",
}

export enum EventsFilter {
  descendAlphabetEvent = "DESCEND_ALPHABET_EVENT",
  ascendAlphabetEvent = "ASCEND_ALPHABET_EVENT",
  descendAlphabetAccount = "DESCEND_ALPHABET_ACCOUNT",
  ascendAlphabetAccount = "ASCEND_ALPHABET_ACCOUNT",
  newerStreamDate = "NEWER_STREAM_DATE",
  olderStremDate = "OLDER_STREAM_DATE",
}
export enum FilterTypesClients {
  default = "DEFAULT",
  accountName = "ACCOUNT_NAME",
  fullname = "FULLNAME",
  country = "COUNTRY",
  firaProducts = "FIRA_PRODUCTS",
  broadcastingActive = "BROADCASTING_ACTIVE",
  broadcastingNotActive = "BROADCASTING_NOT_ACTIVE",
  accountState = "ACCOUNT_STATE",
  signupState = "SIGNUP_STATE",
  activeFrom = "ACTIVE_FROM",
}
export interface BusinessClient {
  clientId: string;
  accountEmail: string;
  fullName: string;
  accountState: string;
  country: string;
  createdAt: Date;
  currentSubscription: {
    alias: string;
    planDescription: string;
    planPeriodType: string;
    productsOn: string[];
  };
  liveEvent: boolean;
}

export interface ClientContextData {
  clients: BusinessClient[];
  setClientList: () => void;
  accountOwner: AccountOwner | undefined;
  generalData: GeneralData | undefined;
  lastEvent: NextEventList[];
  storeList: StoreData[];
  operatorList: Operator[];
  isLoading: boolean;
  isError: boolean;
  isErrorProducts: boolean;
  loadingData: { [key: string]: boolean };
  fetchClients: (
    filterType: string,
    valueFilter: string,
    sortType: string
  ) => void;
  getAccountOwnerInfo: (accountId: string) => void;
  getGeneralAccountInfo: (accountId: string) => void;
  getLastEventInfo: (accountId: string) => void;
  getStoresListInfo: (
    accountId: string,
    storeName: string,
    sortType: string,
    offSet: number,
    limit: number
  ) => void;
  setDetailsData: () => void;
  getOperatorList: (
    accountId: string,
    name: string,
    sortType: string,
    offSet: number,
    limit: number
  ) => void;
  setEditMode: (mode: boolean) => void;
  isEdit: boolean;
  updateFiraProducts: (accountId: string, body: string[]) => void;
  responseCreate: ResponseCreateBusinessClient;
  createBusinessClient: (body: BusinessClientCreate) => void;
  setError: (isError: boolean) => void;
  toggleSignUp(accountId: string): void
  togglePasswordField(accountId: string): void
  setSignUpExpressRole(accountId: string, role: UserRoles): void,
  toggleCustomField(accountId: string): void
  saveCustomFields(accountId: string, customData: { [key: string]: string }): void
}

export enum FiraProducts {
  firaWeb = "FIRA_WEB",
  firaTv = "FIRA_TV",
  proseller = "PROSELLER",
}

export interface StoreData {
  storeName: string;
  state: string;
  operators: number;
  storeId: string;
  logo: string | null;
}

export interface Operator {
  image: string;
  name: string;
  status: string;
  role: string;
  id: string;
}

export interface AccountOwner {
  username: string;
  name: string;
  accountEmail: string;
  createdAt: string;
  contact: {
    emails: string;
    phones: [
      {
        country: string;
        operator: string;
        number: string;
        type: string;
      }
    ];
    socialMedia: string;
    webpages: string[];
  };
  signOnExpress: 'ENABLED' | 'DISABLED' | null
  fieldEnabled: boolean | null
  role: UserRoles | null
  country: string;
  accountState: string;
  productsOn: string[];
  allowCustomFields: boolean | null;
  customFields: {
    [key: string]: string
  } | null
}

export interface GeneralData {
  lives: number;
  minutes: number;
  stores: number;
  users: number;
}

export interface ProductsOn {
  accountId: string;
  products: string[];
}

export interface itemsOption {
  value: string;
  label: string;
  isSelected?: boolean;
  isDisabled?: boolean;
}

export enum FiraSelectStyle {
  default = "",
  outline = "Outline",
  profile = "Profile",
  phonecode = "Phonecode",
}

export interface Phone {
  country: string,
  operator: string,
  number: string,
  type: string,
}

export interface BusinessClientCreate {
  accountEmail: string;
  nameAccount: string;
  userName: string;
  phone?: Phone;
  country: string;
  password: string;
  contactName: string;
  contactLastName: string;
  sizeType: string;
  sector: string;
  webPage: string;
  products: string[];
}

export enum OptionEmployees {
  RANGE_1_TO_10 = "RANGE_1_TO_10",
  RANGE_11_TO_50 = "RANGE_11_TO_50",
  RANGE_51_TO_100 = "RANGE_51_TO_100",
  RANGE_101_TO_250 = "RANGE_101_TO_250",
  RANGE_251_TO_1000 = "RANGE_251_TO_1000",
  RANGE_1001_TO_5000 = "RANGE_1001_TO_5000",
  RANGE_GREATER_THAN_5000 = "RANGE_GREATER_THAN_5000",
}

export interface ErrorListResponse {
  field: string;
  contentValue: string;
  message: string;
}
export interface ResponseCreateBusinessClient {
  code: string;
  message: string;
  success: boolean;
  errorDateTime: string;
  errorList: errorListResponse[];
}
